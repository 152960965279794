const useFileItemCapabilities = (item) => {
  switch (item.status) {
    case 'BINARY_INGESTION':
    case 'BINARY_INGESTION_COMPLETE':
    case 'BINARY_INGESTION_FAILED':
    case 'OCR':
    case 'OCR_FAILED':
    case 'OCR_COMPLETE':
    case 'CLASSIFICATION':
    case 'CLASSIFICATION_FAILED':
      return {
        type: true,
        description: true,
        metadata: true,
        name: true,
      };
    case 'CLASSIFICATION_COMPLETE':
    case 'METADATA_EXTRACTION':
    case 'METADATA_EXTRACTION_FAILED':
      return {
        metadata: true,
        name: true,
      };
    case 'METADATA_EXTRACTION_COMPLETE':
    case 'NAME_GENERATION':
    case 'NAME_GENERATION_FAILED':
      return {
        name: true,
      };
    default:
      return {};
  }
};

export default useFileItemCapabilities;
