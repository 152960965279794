import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import { Paper, IconButton } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { processingPopupSelectors } from 'redux/selectors';

import withErrorBoundary from 'hocs/withErrorBoundary';

import SimpleBar from 'components/UIKit/SimpleBar';
import { mainActions, processingPopupActions } from 'redux/actions';
import { useDidMount } from 'hooks';
import useStyles from './ProcessingPopup.styles';

function CircularProgressWithLabel({ value }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', ml: 2 }}>
      <CircularProgress variant="determinate" size={32} value={value} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" fontSize={10}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function ProcessingPopup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { orgName } = useParams();

  const [collapsed, setCollapsed] = useState(false);

  const processingPipelines = useSelector(processingPopupSelectors.pipelinesSelector);
  const filesById = useSelector(processingPopupSelectors.filesByIdSelector);
  const isInited = useSelector(processingPopupSelectors.isInitedSelector);

  const processingPipelinesRef = useRef();

  processingPipelinesRef.current = processingPipelines;

  useDidMount(() => {
    if (!isInited) {
      dispatch(processingPopupActions.fetchProcessingPipelines());
    }
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (processingPipelinesRef.current.length > 0) {
        dispatch(processingPopupActions.fetchProcessingPipelines());
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (processingPipelines.length === 0) {
    return null;
  }

  const renderPipeline = (pipeline) => {
    const fileItem = filesById[pipeline.file_item_id];

    if (!fileItem) {
      return null;
    }

    const getFileUrl = () => {
      switch (fileItem.type) {
        case 'inbox':
          return `/${orgName}/inbox`;
        case 'balance_import':
          return `/${orgName}/balance-import`;
        default:
          return `/${orgName}/${fileItem.immo_id}/scans`;
      }
    };

    const handleTitleClick = () => {
      setTimeout(() => {
        dispatch(mainActions.setHighlightedItemId({ value: fileItem.id }));
      }, 0);
    };

    const getProgress = () => {
      const splitPrepareStep = pipeline.steps.find((step) => step.step_type === 'SPLIT_PREPARE');

      if (splitPrepareStep) {
        return (((splitPrepareStep.completed_items || 0) / splitPrepareStep.total_items) * 100) || 0;
      }

      return 0;
    };

    return (
      <div key={pipeline.id} className={classes.task}>
        <Link to={getFileUrl()} onClick={handleTitleClick} className={classes.fileName}>
          {fileItem.name}
        </Link>
        <CircularProgressWithLabel value={getProgress()} />
      </div>
    );
  };

  const handleClose = () => {
    dispatch(processingPopupActions.close());
  };

  const handleCollapseToggle = () => {
    setCollapsed((v) => !v);
  };

  return (
    <Paper className={cx(classes.root, collapsed && classes.collapsed)}>
      <div className={classes.header}>
        <div>
          {processingPipelines.length}
          {' '}
          splitting
        </div>
        <div>
          <IconButton onClick={handleCollapseToggle}>
            {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      {!collapsed && (
        <SimpleBar className={classes.tasks}>
          {processingPipelines.map((pipeline) => renderPipeline(pipeline))}
        </SimpleBar>
      )}
    </Paper>
  );
}

export default withErrorBoundary(ProcessingPopup);
