import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { fileListActions as actions, fileCreationActions } from 'redux/actions';
import Typography from '@mui/material/Typography';

import UploadButton from 'components/UploadButton';
import FilesDndZone from 'components/FilesDndZone/FilesDndZone';

import { COLUMN_LIST } from 'constants/scanList';
import TableSettingsProvider from 'components/TableSettingsProvider';
import SearchBox from 'components/SearchBox';
import ImmoSubNavigation from 'components/Layout/Navigation/ImmoSubNavigation';

import usePollFiles from 'hooks/usePollFiles';

import ScansTable from './ScansTable';

const ScansPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.resetSort());
    dispatch(actions.setFileTypes({ value: ['scan'] }));
    dispatch(actions.getFileListRequest({}));

    return () => {
      dispatch(actions.reset());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePollFiles();

  const handleSearch = (searchTerm) => {
    dispatch(actions.setSearchTerm({ value: searchTerm }));
    dispatch(actions.getFileListRequest({ }));
  };

  const handleUpload = (files) => {
    dispatch(fileCreationActions.createFilesRequest({ files }));
  };

  const handleDropFiles = (files) => {
    dispatch(fileCreationActions.createFilesRequest({ files }));
  };

  return (
    <Layout
      app="immos"
      searchBox={(
        <SearchBox onSearch={handleSearch} />
      )}
      breadCrumbsContent={[
        <Typography key="3" color="text.primary">
          Scans
        </Typography>,
      ]}
      newButton={(
        <UploadButton onUpload={handleUpload} />
      )}
      subNav={<ImmoSubNavigation />}
      withEditMode
    >
      <FilesDndZone onDrop={handleDropFiles}>
        <TableSettingsProvider tableId="files" columns={COLUMN_LIST}>
          <ScansTable />
        </TableSettingsProvider>
      </FilesDndZone>
    </Layout>
  );
};

export default ScansPage;
