/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  processingPipelines: [],
  filesById: {},
  isInited: false,
};

export const slice = createSlice({
  name: 'processingPopup',
  initialState,
  reducers: {
    fetchProcessingPipelines: () => {},
    fetchProcessingPipelinesSuccess: (state, { payload: { pipelines, newFilesById } }) => {
      state.processingPipelines = pipelines;
      state.filesById = {
        ...state.filesById,
        ...newFilesById,
      };
      state.isInited = true;
    },
    close: (state) => {
      state.processingPipelines = [];
      state.filesById = {};
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
