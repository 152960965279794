import { put } from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';

import { getSplittingState } from 'utils/localstorage/splittingLocalstorage';
import { actions } from '../fileSplittingReducer';

import request from '../../../utils/sagas/request';

import getSubDocs from '../utils/getSubDocs';

function* legacyFileSplittingInit(fileItemId, fileItem, base64ByPageNumber, signedLink) {
  try {
    const { data: { task_groups: taskGroups } } = yield request({
      method: 'post',
      url: '/tasks/_search',
      data: {
        processing_task_types: [
          'split_preparation', 'split_finalizing',
        ],
        file_item_ids: [fileItemId],
      },
    });

    const { data: { task_groups: preparationTaskGroups } } = yield request({
      method: 'post',
      url: '/tasks/_search',
      data: {
        processing_task_types: ['split_preparation'],
        file_item_ids: [fileItemId],
      },
    });

    const task = taskGroups[0].tasks[0];
    const preparationTask = preparationTaskGroups[0].tasks[0];

    const localStorageSplittingState = getSplittingState(fileItemId);

    yield put(actions.initSuccess({
      fileItem,
      pdfUrl: signedLink,
      subDocs: localStorageSplittingState?.subDocs || getSubDocs(task.document_split_details),
      splitPointConfidences: task.document_split_details.split_point_confidences,
      preparationTaskId: preparationTask.id,
      base64ByPageNumber,
    }));
  } catch (e) {
    NM.error('Something went wrong');
  }
}

export default legacyFileSplittingInit;
