import request from 'api/request';

import transformDocumentSearchResultItems from './utils/transformDocumentSearchResultItems';

const getSort = (sort) => {
  if (!sort) {
    return {};
  }

  if (sort.isMetadata) {
    return {
      sortOptions: `METADATA_FIELD_${sort.sort_direction}`,
      sortedMetadataFieldId: sort.sort_by,
    };
  }

  return {
    sortOptions: `${sort.sort_by}_${sort.sort_direction}`,
  };
};

const loadFiles = async ({
  immoId,
  searchTerm,
  subFolder,
  originId,
  documentClasses,
  sort,
  limit,
  offset,
  fileTypes,
  benchmarkTypes,
}) => {
  const transformedSort = getSort(sort);

  if (searchTerm.length > 2) {
    if (fileTypes?.includes('scan') || fileTypes?.includes('benchmark')) {
      const { data } = await request({
        method: 'post',
        url: 'files/_text-search',
        data: {
          search_term: searchTerm,
          immo_ids: immoId ? [immoId] : undefined,
          file_types: fileTypes || ['scan'],
        },
      });

      return {
        files: data.file_items,
        hasMore: false,
      };
    }
    const { data } = await request({
      method: 'post',
      url: 'documents/_text-search',
      data: {
        search_term: searchTerm,
        immo_ids: immoId ? [immoId] : undefined,
        document_class_ids: documentClasses?.length ? documentClasses : undefined,
        grouping_types: subFolder ? [subFolder] : undefined,
        origin_ids: originId ? [originId] : undefined,
      },
    });

    return {
      files: transformDocumentSearchResultItems(data.items),
      hasMore: false,
    };
  }
  const { data } = await request({
    method: 'post',
    url: '/files/_search',
    data: {
      immo_id: immoId,
      parent_id: subFolder,
      origin_id: originId,
      limit,
      offset,
      sort_options: transformedSort.sortOptions,
      sorted_metadata_field_id: transformedSort.sortedMetadataFieldId,
      document_class_ids: documentClasses?.length ? documentClasses : undefined,
      benchmark_document_class_ids: benchmarkTypes?.length ? benchmarkTypes : undefined,
      file_types: fileTypes || undefined,
    },
  });

  return {
    files: data.files,
    hasMore: data.files.length === limit,
  };
};

export default loadFiles;
