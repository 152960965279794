import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';

import { actions } from './processingPopupReducer';
import * as selectors from './processingPopupSelectors';

import request from '../utils/sagas/request';

export function* fetchProcessingPipelines() {
  const filesById = yield select(selectors.filesByIdSelector);

  try {
    const { data } = yield request({
      method: 'post',
      url: 'pipelines/_search',
      data: {
        latest_only: true,
        workflows: ['SPLIT_PREPARATION'],
        statuses: ['STARTED'],
        my_only: true,
      },
    });

    const pipelines = [];
    const fileIds = [];

    data.pipelines.forEach((item) => {
      pipelines.push(item.pipelines[0]);

      if (!filesById[item.file_item_id]) {
        fileIds.push(item.file_item_id);
      }
    });

    const newFilesById = {};

    if (fileIds.length > 0) {
      const { data: filesData } = yield request({
        method: 'post',
        url: '/files/_search',
        data: {
          file_item_ids: fileIds,
        },
      });

      filesData.files.forEach((file) => {
        newFilesById[file.id] = file;
      });
    }

    yield put(actions.fetchProcessingPipelinesSuccess({ pipelines, newFilesById }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.fetchProcessingPipelines.type, fetchProcessingPipelines),
  ]);
}
