import {
  all, put, takeLatest, select, delay,
} from 'redux-saga/effects';

import { NotificationManager as NM } from 'react-notifications';

import { setIsGlobalLoading } from 'redux/actions';

import { currentImmoSelectors, filtersSelectors } from 'redux/selectors';
import loadFiles from 'utils/loadFiles/loadFiles';
import transformFileItems from 'redux/files/fileList/utils/transformFileItems';
import { actions } from './fileListReducer';
import * as selectors from './fileListSelectors';

import request from '../../utils/sagas/request';

export function* getFileListFlow({ payload = {} }) {
  yield delay(0);
  const { loadMore } = payload;
  const subFolder = yield select(filtersSelectors.groupSelector);
  const documentClasses = yield select(filtersSelectors.typesSelector);
  const benchmarkTypes = yield select(filtersSelectors.benchmarkTypesSelector);
  const originId = yield select(selectors.originIdSelector);
  const sort = yield select(selectors.sortSelector);
  const searchTerm = yield select(selectors.searchTermSelector);
  const immoId = yield select(currentImmoSelectors.idSelector);
  const offset = loadMore ? yield select(selectors.offsetSelector) : 0;
  const newOffset = offset + 30;
  const fileTypes = yield select(selectors.fileTypesSelector);
  yield put(setIsGlobalLoading(true));

  try {
    const { files, hasMore } = yield loadFiles({
      immoId,
      searchTerm,
      subFolder,
      originId,
      documentClasses,
      sort,
      limit: 30,
      offset,
      fileTypes,
      benchmarkTypes,
    });

    yield put(actions.getFileListSuccess({
      data: {
        files: transformFileItems(files),
      },
      hasMore,
      loadMore,
      offset: newOffset,
    }));

    yield put(actions.fetchPipelinesRequest({ fileItems: files }));
  } catch (e) {
    console.error(e);

    yield put(actions.getFileListFailure());
    NM.error('Something went wrong');
  }

  yield put(setIsGlobalLoading(false));
}

export function* fetchPipelinesFlow({ payload: { fileItems, itemIds: itemIdsProp } }) {
  const itemIds = itemIdsProp
    || fileItems.filter((item) => item.status && item.status !== 'COMPLETED').map((item) => item.id);

  if (itemIds.length === 0) {
    return;
  }

  try {
    const { data } = yield request({
      method: 'post',
      url: 'pipelines/_search',
      data: {
        file_item_ids: itemIds.slice(0, 250),
        latest_only: true,
      },
    });

    yield put(actions.fetchPipelinesSuccess({ pipelines: data.pipelines }));
  } catch (e) {
    console.error(e);
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getFileListRequest.type, getFileListFlow),
    takeLatest(actions.fetchPipelinesRequest.type, fetchPipelinesFlow),
  ]);
}
