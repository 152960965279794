import { useRef } from 'react';
import { useDidMount, useWillUnmount } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';

import { fileListSelectors } from 'redux/selectors';
import { fileListActions } from 'redux/actions';

import request from 'api/request';

const isFileInProgress = (item) => {
  if (
    !item.status
    || item.status.includes('_FAILED')
    || item.status === 'COMPLETED'
    || item.status === 'SPLIT_PREPARE_COMPLETE'
    || item.status === 'SPLIT_REQUESTED'
    || item.status === 'CREATED'
  ) {
    return false;
  }

  return true;
};

const usePollFiles = () => {
  const dispatch = useDispatch();
  const fileItemsRef = useRef([]);
  const intervalIdRef = useRef(null);

  const fileItems = useSelector(fileListSelectors.itemsSelector);

  fileItemsRef.current = fileItems;

  const pollFiles = async () => {
    if (!fileItemsRef.current || fileItemsRef.current.length === 0) {
      return;
    }

    const fileIds = fileItemsRef.current
      .filter((item) => isFileInProgress(item))
      .map((item) => item.id).slice(0, 200);

    if (fileIds.length > 0) {
      try {
        dispatch(fileListActions.fetchPipelinesRequest({ itemIds: fileIds }));

        const { data: { files } } = await request({
          method: 'post',
          url: '/files/_search',
          data: {
            file_item_ids: fileIds,
            limit: 300,
          },
        });

        files.forEach((file) => {
          dispatch(fileListActions.updateFile({ file }));
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useDidMount(() => {
    intervalIdRef.current = setInterval(() => {
      pollFiles();
    }, 3000);
  });

  useWillUnmount(() => {
    clearInterval(intervalIdRef.current);
  });
};

export default usePollFiles;
