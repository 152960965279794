import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager as NM } from 'react-notifications';

import Button from '@mui/material/Button';

import { Link, useParams } from 'react-router-dom';

import { fileListActions as actions, processingPopupActions } from 'redux/actions';
import { fileListSelectors } from 'redux/selectors';

import request from 'api/request';

const ScanProcessingButton = ({ item }) => {
  const dispatch = useDispatch();
  const { immoId, orgName } = useParams();
  const [isSubmitting, setIsSubmitting] = useState();
  const pipelines = useSelector(fileListSelectors.pipelinesPerItemSelector);

  const pipeline = pipelines && pipelines[item.id];

  const handleSplit = async () => {
    setIsSubmitting(true);
    try {
      await request({
        method: 'post',
        url: '/pipelines/_prepare_split',
        data: {
          file_item_id: item.id,
        },
      });
      dispatch(actions.updateFile({ file: { ...item, status: 'SPLIT_PREPARE_REQUESTED' } }));
      dispatch(processingPopupActions.fetchProcessingPipelines());
    } catch (e) {
      NM.error('Something went wrong');
    }
    setIsSubmitting(false);
  };

  const renderSplitPreparation = () => {
    const splitPrepareStep = pipeline?.steps.find((step) => step.step_type === 'SPLIT_PREPARE');

    if (splitPrepareStep) {
      return `Preparing splitting (${splitPrepareStep.completed_items || 0}/${splitPrepareStep.total_items})...`;
    }

    return 'Preparing splitting...';
  };

  if (item.status === 'CREATED') {
    return (
      <Button
        variant="text"
        disabled={isSubmitting}
        onClick={handleSplit}
      >
        Split
      </Button>
    );
  }

  if (item.status === 'SPLIT_PREPARE_REQUESTED' || item.status === 'IMAGE_SLICING_REQUESTED') {
    return <Button disabled variant="text">{renderSplitPreparation()}</Button>;
  }

  if (item.status === 'SPLIT_PREPARE_COMPLETE') {
    return (
      <Button
        component={Link}
        variant="text"
        to={`/${orgName}/${immoId}/scans/${item.id}/splitting`}
      >
        Verify splitting
      </Button>
    );
  }

  if (item.status === 'SPLIT_REQUESTED') {
    return (
      <Button
        component={Link}
        variant="text"
        to={`/${orgName}/${immoId}/scans/${item.id}/sub-docs`}
      >
        View sub docs
      </Button>
    );
  }

  return null;
};

export default ScanProcessingButton;
